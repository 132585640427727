<template>
  <div class="managed-group-list-item" @click.prevent="$emit('selected')">
    <div class="left">
        <chat-thumbnail size="large" :has-custom-avatar="true"
        :chat-id="managedGroup.id"
        :chat-owner-id="managedGroup.ownerId"
        :tenant-id="tenantId"
        ></chat-thumbnail>
    </div>
    <div class="right">
      <div class="name">
        {{ displayName }} ({{ managedGroup.membership ? managedGroup.membership.length : '-' }} {{ (managedGroup.membership && managedGroup.membership.length == 1 ? 'member' : 'members') }})
      </div>
      <div class="last-activity" :key="timeAgoRefreshKey">
        Last activity {{ getLastActivityHuman(managedGroup) }}
      </div>
    </div>
  </div>
</template>

<script>
import ChatThumbnail from '../../../components/Chats/ChatThumbnail.vue';
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
export default {
  components: { ChatThumbnail },
  props: ["managedGroup", "tenantId", "orgId"],
  mixins: [Dates, TimeAgoRefresher],
  computed: {
    displayName() {
      if (
        this.managedGroup.displayName &&
        this.managedGroup.displayName.length
      ) {
        return this.managedGroup.displayName;
      }
      return "Unknown";
    }
  },
};
</script>

<style scoped lang="scss">
.managed-group-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
  }
}
</style>